<template>
  <b-tabs lazy @input="tabsInputEvent" v-model="tabIndex" @changed="tabsChange">
    <template v-for="(item, index) in items">
      <b-tab :title="item.name" :key="index">
        <RequiredDocumentsList
          :tabId="item.id"
          :statuses="item.statuses"
          :description="item.description"
          :tabIndex="tabIndex"
        />
      </b-tab>
    </template>
  </b-tabs>
</template>

<script>
import RequiredDocumentsList from "./required-documents-list.vue";
import { getRequiredDocumensTabs } from "@/api/requiredDocuments.js";
export default {
  components: {
    RequiredDocumentsList,
  },
  data() {
    return {
      tabIndex: 0,
      items: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    tabsInputEvent(v) {},
    init() {
      getRequiredDocumensTabs().then((result) => {
        this.items = result.data;
      });
    },
    tabsChange(v, e) {
      console.log(this.$route.params.tabIndex);
      this.tabIndex = parseInt(this.$route.params.tabIndex ?? 0);
    },
  },
};
</script>

<style></style>
