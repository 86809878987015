<template>
  <KValidationObserver ref="observer">
    <k-form-group
      label-class="require-mark"
      label-suffix="amendReason"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      label-for="amendReason"
    >
      <KValidationProvider
        :rules="{
          required: true,
          max: 2000,
        }"
        v-slot="{ errors }"
        slim
        name="amendReason"
        ref="amendReason"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-textarea v-model="amend" rows="4" />
      </KValidationProvider>
    </k-form-group>
  </KValidationObserver>
</template>

<script>
export default {
  data() {
    return {
      amend: "",
    };
  },
};
</script>

<style></style>
