import http from "fe-infrastractures/src/utils/http";

export const getRequiredDocumensTabs = (data) => {
    return http.get("/api/required-documens-portal/tabs", data, {});
};
export const getRequiredDocumensPage = (data) => {
    return http.post("/api/required-documens-portal/page", data, {});
};
export const getRequiredDocumensDetail = (data) => {
    return http.post("/api/required-documens-portal/details", data, {});
};
export const saveRequiredDocumensEdit = (data) => {
    return http.post("/api/required-documens-portal/edit", data, {});
};
export const getRequiredDocumensRejectionReasonView = (data) => {
    return http.post("/api/required-documens-portal/rejection-reason/view", data, {});
};
export const saveRequiredDocumensAmend = (data) => {
    return http.post("/api/required-documens-portal/amend", data, {});
};
export const getRequiredDocumensEditUploadFilesInfo = (data) => {
    return http.get("/api/upload-config/requiredDocuments", data, {});
};

export const documentIsExit = (model) => {
    return http.post(`/api/docs/actions/IsExit`, { model }, { loading: true });
};

export const downloadDocumentUrl = (model) => {
    return http.down("/api/docs/actions/download", { model }, { loading: false });
};
export const uploadFile = (data) => {
    return http.post("/api/docs/upload", data, { useBody: true });
};