<template>
  <div class="layoutContent">
    <HomeTitle :description="description" />
    <div class="contentParty">
      <k-alert-msg ref="lblMsg" />
      <div class="wapModel layoutTableCard">
        <div v-for="item in wapTableData" :key="item.id" class="tableGroup">
          <div class="cardHead"></div>
          <div class="cardBody">
            <div
              class="tableList"
              v-for="(item2, index) in fields"
              :key="index"
            >
              <template v-if="item2.key != 'actions'">
                <div class="control-field">
                  {{ item2.label }}{{ $g(":") }}&nbsp;
                </div>
                <div class="control-value">
                  {{ item[item2.key] }}
                </div>
              </template>
              <template v-if="item2.key == 'actions'">
                <div class="text-right" style="flex: 1">
                  <div v-if="item.statusFunctions.length > 0">
                    <template v-for="(item3, index) in item.statusFunctions">
                      <k-button
                        variant="outline-secondary"
                        :class="index != 0 ? 'ml-2' : ''"
                        @click="actionsClickEvent(item3.value, item)"
                        :key="index"
                        v-text="item3.text"
                        v-permission="actionsPermissionCode(item3.value)"
                      />
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <konn-grid
        ref="selectableTable"
        :has-action="true"
        :has-check-box="false"
        :fields="fields"
        :data-source="dataSource"
        :get-condition="getCondition"
        @items-get="allGet"
        class="wapTableHide"
      >
        <template #cell(actions)="row">
          <div v-if="row.item.statusFunctions.length > 0">
            <template v-for="(item, index) in row.item.statusFunctions">
              <k-button
                variant="outline-secondary"
                :class="index != 0 ? 'ml-2' : ''"
                @click="actionsClickEvent(item.value, row.item)"
                :key="index"
                v-text="item.text"
                v-permission="actionsPermissionCode(item.value)"
              />
            </template>
          </div>
          <div v-else></div>
        </template>
      </konn-grid>
    </div>
    <k-popwindow
      id="popViewRejectionReason"
      :title="$g('reject')"
      :hideOk="true"
      @hidden="popViewRejectionReasonHidden"
    >
      <ViewRejectionReason :content="ViewRejectionReasonContent" />
    </k-popwindow>
    <k-popwindow
      id="popAmendPopwindow"
      ref="popAmendPopwindow"
      :title="$g('amend')"
      :checkSelected="false"
      @ok="saveAmend"
      :before-close="onAmendBeforeClose"
    >
      <AmendPopwindow ref="amendPopwindow" />
    </k-popwindow>
  </div>
</template>

<script>
import { PW_Worker_Portal_Code } from "@/router/permissionCode";
import HomeTitle from "@/views/components/homeTitle";
import ViewRejectionReason from "./components/view-rejection-reason.vue";
import AmendPopwindow from "./components/amend-popwindow.vue";
import {
  getRequiredDocumensPage,
  getRequiredDocumensRejectionReasonView,
  saveRequiredDocumensAmend,
} from "@/api/requiredDocuments.js";
import { saveAlert, repResult } from "fe-infrastractures";

const actionsEnum = {
  empty: 0,
  edit: 32,
  viewRejectionReason: 8,
  view: 16,
  amend: 64,
};
export default {
  components: {
    HomeTitle,
    ViewRejectionReason,
    AmendPopwindow,
  },
  props: {
    tabId: {
      type: String,
      default: null,
    },
    statuses: {
      type: Array,
      default: [],
    },
    description: {
      type: String,
      default: null,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      PW_Worker_Portal_Code,
      fields: [
        {
          key: "documentCode",
          label: "documentID",
        },
        {
          key: "documentName",
          label: "documentName",
        },
        {
          key: "election",
          label: "election",
        },
        {
          key: "status",
          label: "status",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      ViewRejectionReasonContent: "",
      actionsClickItem: {},
      wapTableData: null,
    };
  },
  computed: {
    dataSource() {
      return getRequiredDocumensPage;
    },
  },
  methods: {
    getCondition() {
      return { requiredDocumentationProcessingPageSettingId: this.tabId };
    },
    allGet(e) {
      this.wapTableData = e;
    },
    actionsPermissionCode(value) {
      let permissionCode;
      switch (value) {
        case actionsEnum.edit:
          permissionCode = this.PW_Worker_Portal_Code.RequiredDocuments.Submit;
          break;
        case actionsEnum.viewRejectionReason:
          permissionCode =
            this.PW_Worker_Portal_Code.RequiredDocuments.ViewRejectionReason;
          break;
        case actionsEnum.view:
          permissionCode = this.PW_Worker_Portal_Code.RequiredDocuments.View;
          break;
        case actionsEnum.amend:
          permissionCode = this.PW_Worker_Portal_Code.RequiredDocuments.Amend;
          break;
        default:
          permissionCode = "";
          break;
      }
      return permissionCode;
    },
    actionsClickEvent(value, item) {
      this.actionsClickItem = item;
      switch (value) {
        case actionsEnum.edit:
          this.editClickEvent(item);
          break;
        case actionsEnum.viewRejectionReason:
          this.viewRejectionReasonClickEvent(item);
          break;
        case actionsEnum.view:
          this.viewClickEvent(item);
          break;
        case actionsEnum.amend:
          this.amendClickEvent();
          break;
        default:
          break;
      }
    },
    amendClickEvent() {
      this.$bvModal.show("popAmendPopwindow");
    },
    viewClickEvent(item) {
      let query = {
        ...this.$route.query,
        tabIndex: this.tabIndex,
        detailId: item.id,
      };
      if (
        item.electionId &&
        item.electionId != "00000000-0000-0000-0000-000000000000"
      ) {
        query.electionId = item.electionId;
      }
      this.$router.push({
        name: "requiredDocumentsView",
        query: query,
      });
    },
    viewRejectionReasonClickEvent(item) {
      let query = {
        requiredDocumentationProcessingId: item.id,
        electionId: item.electionId,
        statusId: item.statusId,
      };
      getRequiredDocumensRejectionReasonView(query).then((result) => {
        this.ViewRejectionReasonContent = result.data?.reasonText;
        this.$bvModal.show("popViewRejectionReason");
      });
    },
    popViewRejectionReasonHidden() {
      this.ViewRejectionReasonContent = "";
    },
    editClickEvent(item) {
      let query = {
        ...this.$route.query,
        detailId: item.id,
        tabIndex: this.tabIndex,
      };
      if (
        item.electionId &&
        item.electionId != "00000000-0000-0000-0000-000000000000"
      ) {
        query.electionId = item.electionId;
      }
      this.$router.push({
        name: "requiredDocumentsEdit",
        query: query,
      });
    },
    saveAmend() {
      let content = this.$refs.popAmendPopwindow.amend;
      let query = {
        reasonText: content,
        requiredDocumentationProcessingId: this.actionsClickItem.id,
        electionId: this.actionsClickItem.electionId,
      };
      saveRequiredDocumensAmend(query).then((result) => {
        if (result.data.status == repResult.success) {
          this.$refs.selectableTable.refresh();
        }
        this.$refs.lblMsg.message(result.data.status, result.message);
      });
    },
    async onAmendBeforeClose() {
      const isValid = await this.$refs.amendPopwindow.$refs.observer.validate();
      let pmOpt = null;
      if (isValid) {
        pmOpt = this.$bvModal
          .msgBoxConfirm(this.$g("const.confirm.save"), {
            title: this.$g("const.confirm.confirm"),
            cancelTitle: this.$g("const.confirm.saveYes"),
            okTitle: this.$g("const.confirm.no"),
            cancelVariant: "primary",
            okVariant: "secondary",
            noCloseOnBackdrop: true,
          })
          .then((value) => {
            return value != null && !value;
          });
        return pmOpt;
      }
      return false;
    },
  },
};
</script>

<style scoped>
</style>
